import axios from 'axios';
import AuthService from './auth-header';
// /*dev*/const API_URL = 'http://192.168.1.81:5000/api/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/';

let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.81:5000/api/';
}
class ElasticsearchService {
    

    async search(query, page, pageSize) {
        try {
            const res = await axios.post(API_URL + 'search?page=' + page + '&pageSize=' + pageSize, query,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async createHistory(data) {
        try {
            const res = await axios.post(API_URL + 'search/history', data,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async checkCandidateExist(data) {
        try {
            const res = await axios.post(API_URL + 'search/candidates', data,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    

}

export default new ElasticsearchService();