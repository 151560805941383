import axios from 'axios';
import AuthService from './auth-header';
// /*dev*/const API_URL = 'http://192.168.1.81:5000/api/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/';

let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.81:5000/api/';
}
class SearchesService {
    async create(data) {
        try {
            const res = await axios.post(API_URL + 'saved/search', data,
        { 
            headers: AuthService.authHeader()
        });
        //console.log(res.data)
        return res.data;
        }
        catch (err) {
            console.log(err.response)
            return err.response;
        }
        
    }

    async getSearches(user) {
        try {
            const res = await axios.get(API_URL + 'saved/search?user=' + user,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    async getRecentSearches(user) {
        try {
            const res = await axios.get(API_URL + 'saved/search/recent?user=' + user,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async getSimilarSearches(keywords) {
        try {
            const res = await axios.get(API_URL + 'saved/search/similar?keywords=' + keywords ,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    // async update(id) {
    //     try {
    //         const res = await axios.put(API_URL + 'admin/config/:' + id,
    //         { 
    //             headers: AuthService.authHeader()
    //         });
    //         //console.log(res.data)
    //         return res.data
    //     }
    //     catch(err) {
    //         console.log(err.response)
    //         return err.response
    //     }
    // }

    // async delete(id) {
    //     try {
    //         const res = await axios.delete(API_URL + 'admin/config/:' + id,
    //         { 
    //             headers: AuthService.authHeader()
    //         });
    //         //console.log(res.data)
    //         return res.data
    //     }
    //     catch(err) {
    //         console.log(err.response)
    //         return err.response
    //     }
    // }

}

export default new SearchesService();